<template>
  <div class="content">
    <div id="viewQrcode" ref="viewQrcode"></div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
export default {
  name: 'qrCode',
  props: {
    courseId: {
      type: [Number, String]
    },
    code: {
      type: [Number, String]
    }
  },
  data() {
    return {
      viewQr: `http://10.1.63.60:8080?courseId=${this.courseId}&code=${this.code}`,
    }
  },
  mounted() {
    this.getQrcode()
  },
  methods: {
    getQrcode() {
      this.$nextTick(()=>{
        let viewQrcode = new QRCode('viewQrcode',{
          width: 400, // 设置宽度，单位像素
          height: 400, // 设置高度，单位像素
          text: this.viewQr  // 设置二维码内容或跳转地址
        });
        document.getElementById("viewQrcode").removeAttribute('title');
      });
    }
  },
  beforeDestroy() {
    this.$refs.viewQrcode.innerHTML = ''
  }
}
</script>

<style lang="scss" scoped>
.content{
  width: 200px;
  height: 200px;
  background: #fff;
  margin: 0 auto;
}
</style>