<template>
  <!--直播菜单详情-->
  <div>
    <div v-if="menuDetail.menuType == 3" v-html="menuDetail.richText" class="wxq_richText"></div>
    <template v-if="menuDetail.menuType == 4">
      <!--      <div v-for="(item,index) in videoMenuList"></div>-->
      <div class="wxq_video_wrap" v-if="videoMenuList.length > 0">
        <div class="wxq_video" v-for="(item, index) in videoMenuList" :key="index" @click="clickPlay(item)">
          <video
            id="videoer"
            class="wxq_pageImg"
            :src="item.filePath"
            :autoplay="false"
          >
          </video>
          <!-- <i
            class="start-play"
            v-if="item.isShow"
            @click.stop="changeVideoStatus(item,index)"
          ></i> -->
          <p class="wxq_detail_title">{{ item.fileName }}</p>
        </div>
      </div>
    </template>
    <template v-else></template>
    <!--评价-->
    <template v-if="menuDetail.menuType == 2">
      <div class="wxq_estimate">
        <el-popover
          ref="popoverRef"
          placement="bottom-end"
          width="1000"
          trigger="click">
          <div>
            <el-input type="textarea" v-model="formData.content"></el-input>
            <div class="wxq_score">
              <p>课程评分
                <el-rate v-model="formData.score" allow-half></el-rate>
              </p>
              <el-button class="btn btn_blue" @click="addEvaluation">发布</el-button>
            </div>
          </div>
          <!-- 审核跳转过来不能评价 -->
          <el-button slot="reference" class="btn btn_blue" v-if="isDisabled == 0 && review !='courseReview'">发表评价</el-button>
        </el-popover>
      </div>
        <template v-for="(item,index) in evaList" >
          <div  class="wxq_evaluate"
            :key="index"
            v-if="evaList.length > 0"
          >
            <img :src="item.avatar != '' ? item.avatar : avatar" alt="">
            <div class="wxq_evaluate_detail">
              <p class="wxq_evaluate_title">
                <span class="wxq_username">{{ item.creatorName }}</span>
                <span class="wxq_evaluate_time">{{ item.createTime }}</span>
                <el-rate
                  v-model="item.score"
                  disabled
                  text-color="#ff9900"
                >
                </el-rate>
              </p>
              <p class="wxq_evaluate_content">{{ item.content }}</p>
            </div>
          </div>
        </template>
          <div v-if="evaList.length <= 0" class="bg_img">
            <img  src="../../../../assets/img/studentImg/liveDetail/bg-comment.png" alt="">
            <p>暂无评论</p>
          </div>
    </template>
  </div>
</template>

<script>
import menuDetailAPI from "@/apis/studentsEndAPI/liveMenuDetail";
import API from "@/apis/backStageEndAPI/myCourseAPI";
import CommonUtils from "@/utils";
export default {
  name: "liveMenuDetail",
  props: ['evaluateList', 'isEvaluate', 'menuDetail', 'type', 'review'],
  data() {
    return {
      isDisabled: '',
      avatar: require('@/assets/img/studentImg/liveDetail/tim-avatar.png'),
      formData: {
        itemId: this.menuDetail.courseId,//课程ID
        content: '',//评价内容
        score: 0,//评分1-5
        type: 1//评价类型：1：课程；2：讲师；3：文章
      },
      params: {
        page: 1,
        pageSize: 100
      },
      videoMenuList: [],
      evaList: [],
      //videoElement: [],
      videoIsPlay: false
    }
  },
  watch: {
    evaluateList: {
      handler(val) {
        if(val) {
          this.evaList = val
        }
      },
      immediate:true,
      deep: true
    },
  },
  mounted() {
    this.isDisabled = this.isEvaluate
    if (this.menuDetail.menuType == 4) {
      this.getVideoMenuList()
    }
  },
  methods: {
    // changeVideoStatus(item,index) {
    //   const videoElement = this.videoElement
    //   const vid = document.getElementById("videoer");
    //     if (videoElement && videoElement.length > 0) {
    //     for (let i = 0; i < videoElement.length; i++) {
    //       if (i === index) {
    //         item.isShow = false
    //         this.videoElement[i+1].play()
    //       }
    //     }
    //   }
    //   vid.addEventListener('ended', function () { //结束
    //      item.isShow = true
    //   }, false);
    // },
    getVideoMenuList() { //获取视频列表
      menuDetailAPI.getVideoMenuList(
        this.menuDetail.courseId,
        this.menuDetail.menuId,
        CommonUtils.parseToParams(this.params)).then(res => {
        if (res && !res.status) {
          this.videoMenuList = res.dataList
          // this.videoMenuList.length && this.videoMenuList.map( item => {
          //   this.$set(item,'isShow' ,true)
          // })
        }
      })
    },
    clickPlay(val) { //点击视频播放
      // if (this.type) {
      //   window.open(val.filePath)
      // } else {
      //   this.$EventBus.$emit('sendBus', val)
      // }
      this.$EventBus.$emit('sendBus', val ,true)
    },
    addEvaluation() {//添加评价
      if (sessionStorage.getItem('educationToken') || localStorage.getItem('educationToken')) {
        if (!this.formData.content) {
          this.$message.warning('请输入评价内容')
        } else if (!this.formData.score) {
          this.$message.warning('请选择课程评分')
        } else {
          API.getEvaluation(this.formData).then(res => {
            this.$refs.popoverRef.doClose()
            this.isDisabled = 1
            this.$emit('addSuccess')
          })
        }
      } else {
        this.$message.warning('请先登录后再进行评价')
        this.$store.state.isLogin = true
      }
    },
  }
}
</script>

<style scoped lang="scss">
.wxq_video_wrap {
	display: flex;
	flex-wrap: wrap;
	padding-bottom: 15px;
	.wxq_video {
		position: relative;
		padding: 10px;
		width: 285px;
		height: 200px;
		border-radius: 24px;
		background: #fff;
		box-shadow: 0 6px 58px 0 rgba(196, 203, 214, .1);
		cursor: pointer;
		.wxq_pageImg {
			width: 100%;
			height: 100%;
			border-radius: 24px;
		}
		.wxq_detail_title {
			display: block;
			overflow: hidden;
			padding-top: 2px;
			padding-left: 10px;
			width: 95%;
			color: #333;
			text-overflow: ellipsis;
			white-space: nowrap;
			font-weight: 800;
			font-size: 16px;
		}
	}
}
.wxq_estimate {
	text-align: right;
}
.wxq_evaluate {
	display: flex;
	margin-bottom: 30px;
	img {
		margin-right: 20px;
		width: 60px;
		height: 60px;
		border-radius: 50%;
	}
	.wxq_evaluate_detail {
		.wxq_evaluate_title {
			display: flex;
			margin-bottom: 15px;
			.wxq_username {
				overflow: hidden;
				width: 140px;
				color: #0a1629;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
			.wxq_evaluate_time {
				width: 110px;
				color: #ced5e0;
				font-size: 12px;
			}
			span {
				margin-right: 40px;
			}
		}
		.wxq_evaluate_content {
			color: #91929e;
			font-size: 18px;
			line-height: 25px;
		}
	}
}
.bg_img {
	text-align: center;
	img {
		margin-bottom: 14px;
		width: 193px;
		height: 140px;
	}
	p {
		color: #91929e;
		font-size: 18px;
		line-height: 25px;
	}
}
.wxq_score {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 24px;
	p {
		display: flex;
		font-size: 16px;
		.el-rate {
			margin-left: 30px;
		}
	}
}
::v-deep .el-textarea {
	.el-textarea__inner {
		height: 100px;
	}
}
::v-deep .el-rate__icon {
	font-size: 20px;
}
.start-play {
	position: absolute;
	top: 58px;
	left: 111px;
	display: block;
	margin: auto;
	width: 63px;
	height: 63px;
	border-radius: 50%;
	background: linear-gradient(135deg, #1890ff 0%, #0059f4 100%);
	opacity: .7;
	cursor: pointer;
	&::after {
		position: absolute;
		top: 32%;
		left: 40%;
		width: 0;
		height: 0;
		border: 13px solid transparent;
		border-left: 19px solid white;
		content: "";
	}
}
</style>
